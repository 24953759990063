.container{
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #f8fafd;
  .header,
  .banner
  {
    --color-primary: #062e6f;
  }
  .banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 150%;
    height: 500px;
    left: 50%;
    top: 0;
    overflow: hidden;
    transform: translateX(-50%);
    background: center / cover url(https://static.zhubaofang.cn/website/images/banner/shapes-light.png) no-repeat;
    .title{
      font-size: 48px;
      font-weight: 500;
      text-align: center;
      color: var(--color-primary);
    }
    .timeline{
      width: 440px;
      max-width: 52%;
      margin: 56px auto 0;
      text-align: center;
      position: relative;
      padding: 24px 0 0;
      --color-text: #1f1f1f;
      &::before{
        content: "";
        display: block;
        border-top: 1px solid rgba(6, 46, 111, .3);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: scaleY(.5);
        transform-origin: left top;
      }
      ul{
        li{
          margin-bottom: 16px;
          a{
            display: flex;
            color: var(--color-text);
            p{
              flex: 1;
              text-align: left;
              display: flex;
              align-items: center;
              width: 0;
              span{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            em{
              overflow: hidden;
              font-size: 14px;
              line-height: 24px;
              height: 30px;
              display: inline-block;
              position: relative;
              border-top: 1px solid var(--color-primary);
              padding: 0px 30px;
              color: var(--color-primary);
              &::before{
                content: "";
                display: block;
                position: absolute;
                left: calc(100% - 12px);
                bottom: 0px;
                width: 100%;
                border-top: 1px solid var(--color-primary);
                transform: rotate(-69deg) translate(-1px,0px);
                transform-origin: 0px 0px;
              }
              &::after{
                content: "";
                display: block;
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: calc(100% - 12px);
                border-top: 1px solid var(--color-primary);
              }
            }
          }
        }
      }
      .more{
        text-align: left;
        margin-top: 24px;
        a{
          color: var(--color-text);
        }
      }
    }
  }
  .news{
    --color-text: #1f1f1f;
    --color-subtext: #474747;
    padding: calc(500px - 86px) 28px 40px;
    max-width: 1440px;
    margin: auto;
    h2{
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.5rem;
      padding: 30px 0;
      color: var(--color-text);
      text-align: center;
    }
    .news_list{
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
      &::after{
        content: '';
        width: 33.33%;
      }
      .news_item{
        flex: 0 0 calc(33.33% - 10px);
        border-radius: 24px;
        margin-bottom: 32px;
        opacity: 1;
        position: relative;
        -webkit-transition: opacity 1s,-webkit-transform .5s;
        transition: opacity 1s,-webkit-transform .5s;
        transition: transform .5s,opacity 1s;
        transition: transform .5s,opacity 1s,-webkit-transform .5s;
        background-color: #edf2fa;
        &:hover,
        a
        {
          transform: translateY(-5px);
        }
        .news_item_head{
          padding: 24px 32px 16px 32px;
          h3{
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: var(--color-text);
          }
          p{
            letter-spacing: .1px;
            font-size: 1rem;
            line-height: 1.75rem;
            color: var(--color-subtext);
            margin: 8px 0;
            overflow: hidden;
            position: relative;
            white-space: nowrap;
            width: 100%;
          }
          a{
            color: #1677ff;
            font-weight: 500;
            &::after{
              border-radius: 24px;
              content: "";
              display: block;
              height: 100%;
              left: 0;
              pointer-events: auto;
              position: absolute;
              top: 0;
              width: 100%;
            }
            &:hover{
              &::after{
                box-shadow: 5px 5px 0 0 rgba(32,33,37,.06);
              }
              svg{
                transform: translate(4px, -4px);
              }
            }
            svg{
              margin-left: 5px;
              display: inline-block;
              fill: currentColor;
              transition: transform .2s ease, -webkit-transform .2s ease;
              vertical-align: middle;
            }
          }
        }
        .news_item_thumbnail{
          border-radius: 24px;
          padding: 16px;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 1176px) {
    .news{
      .news_list{
        &::after{
          width: 50%;
        }
        .news_item{
          flex: 0 0 calc(50% - 10px);
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    .news{
      h2{
        letter-spacing: -0.25px;
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }
  }
  @media (max-width: 930px) {
    .banner{
      height: 450px;
    }
    .news{
      padding: calc(450px - 86px) 28px 40px;
    }
  }
  @media (max-width: 760px) {
    .news{
      .news_list{
        justify-content: center;
        &::after{
          width: 0;
        }
        .news_item{
          flex: 0 0 100%;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .banner{
      .timeline{
        margin-top: 26px;
      }
    }
  }
  @media only screen and (min-width: 600px) {
    .news{
      h2{
        letter-spacing: -0.5px;
        font-size: 2.25rem;
        line-height: 2.75rem;
      }
      .news_list{
        .news_item{
          max-width: 618px;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 414px) {
    .banner{
      height: 400px;
      justify-content: flex-end;
      padding-bottom: 56px;
      .title{
        font-size: 28px;
      }
      .timeline{
        ul{
          li{
            a{
              em{
                padding: 0 12px;
              }
            }
          }
        }
      }
    }
    .news{
      padding: calc(400px - 86px) 24px 0 24px;
    }
  }
}