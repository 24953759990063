.footer{
  padding: 40px 0 32px;
  text-align: center;
  background: white;
  div{
    display: flex;
    justify-content: center;
    font-size: 12px;
    &,
    a{
      color: rgba(0,0,0,.7);
    }
    a{
      align-self: center;
    }
    &:nth-child(2){
      margin-top: 8px;
    }
  }
}