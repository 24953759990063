.header{
  padding: 0 40px;
  height: 86px;
  display: flex;
  position: relative;
  z-index: 9;
  align-items: center;
  --color-primary: white;
  --color-success: #00b578;
  &,
  a{
    color: var(--color-primary);
  }
  .logo{
    flex: 1;
    font-size: 26px;
    letter-spacing: 3px;
  }
  .menu{
    display: flex;
    letter-spacing: 1px;
    li{
      position: relative;
      margin: 0 20px;
      &:last-child{
        margin-right: 0;
      }
      &.active,
      &:hover{
        &::before{
          width: 100%;
        }
      }
      &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 3px;
        bottom: -10px;
        background: linear-gradient(30deg, var(--color-success), var(--color-success));
        transition: width .4s;
      }
    }
  }
}